$red: rgb(204, 20, 20);
$lighterRed: rgb(216, 115, 115);

#BoardsMenu {
  .title-menu {
    display: inline-flex;
    cursor: pointer;

    ion-title {
      padding-right: 2px;
      font-weight: bold;
    }

    i {
      margin-left: 10px;
      color: var(--ion-color-primary-contrast) !important;
    }
  }

  .items-count {
    color: #fff;
    background-color: $red;
    border-radius: 50%;
    border: solid $red 1px;
    width: 16px;
    height: 16px;
    font-size: 8px !important;
    text-align: center;
    margin-left: 4px;
    padding-top: 2px;
    margin-right: -10px;
    margin-top: -4px;
  }
}

#BoardsMenu_Content {
  ion-list {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  i {
    color: var(--ion-text-color) !important;
    padding-right: 4px;
  }

  ion-item {
    cursor: pointer;
  }

  #BoardInvitationRow {
    color: $lighterRed !important;
    i {
      color: $lighterRed !important;
    }
  }

  #JoinBoardRow {
    i {
      font-style: regular;
    }
  }
}

html.plt-ios {
  #BoardsMenu {
    ion-title {
      padding-left: 0 !important;
      margin-left: 0px;
    }
    .items-count {
      display: inline-block;
      margin-left: 4px;
      position: relative;
      top: -6px;
    }
  }
}
