#AccountEditPage_Content #DetailsForm {
  ion-item {
    ion-icon {
      z-index: 1000;
      margin-top: -36px;
      margin-left: auto;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .circle-picker {
    margin-top: 22px;
    align-self: center;
    margin-bottom: 1px !important;
  }
}
