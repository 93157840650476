html.plt-ios.plt-hybrid {
  #AccountSettingsPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #AccountSettingsPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#AccountSettingsPage_Content {
  ion-list {
    padding-top: 0 !important;
  }

  ion-item-divider {
    ion-label {
      text-transform: uppercase;
    }
  }
}
