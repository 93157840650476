#BoardNewModal {
  --height: 200px;

  ion-card {
    height: 100%;
    margin: 0;
  }

  ion-title {
    padding-inline-end: 0;
    color: var(--ion-text-color);
    text-transform: uppercase;
    font-family: 'Lato-Light';
    font-size: 0.9rem;
  }

  #buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;
    padding-left: 30px;
    padding-right: 30px;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }
}
