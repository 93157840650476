html.plt-ios.plt-hybrid {
  #AccountsListPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #AccountsListPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

html.plt-ios {
  #AccountsListPage_Content {
    ion-list {
      margin-top: 0;
      z-index: 100;
    }
  }
}

#AccountsListPage_Content {
  .no-items {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: #888;
  }
}
