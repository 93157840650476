.widget {
  ion-card-header {
    padding: 0;
    padding-left: 20px;
    
    ion-toolbar {
      --min-height: 30px;

      ion-card-title {
        padding-top: 12px;
        font-weight: bold;
        font-size: 0.85rem;
      }
    }
  }


  ion-item {
    cursor: pointer;
  }
}
