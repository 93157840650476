html.plt-ios.plt-hybrid {
  #CategoriesListPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #CategoriesListPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

html.plt-ios {
  #CategoriesListPage_Content {
    ion-list {
      margin-top: 0;
      z-index: 100;
    }
  }
}

#CategoriesListPage_Content {
  ion-list {
    margin-top: -7px;
    z-index: 100;
    padding-left: 12px;
    padding-right: 12px;
  }

  ion-item-divider {
    padding: 5px;
    padding-left: 16px;
    text-transform: uppercase;
  }

  .name {
    margin-left: 13px;
    flex: 1;
    align-self: center;
    line-height: 1.8rem;
    cursor: pointer;
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .no-items {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: #888;
  }
}

.category-small-fab-button {
  --border-radius: 8px;
  position: relative;
  right: 120px;
  align-self: flex-end;

  &.small {
    width: 160px;
  }
  &.smaller {
    width: 140px;
  }
}
