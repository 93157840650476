ion-fab-button {
  &.small-edit {
    --border-radius: 8px;
    width: 120px;
  }
}

#BankTransactionEditPage_Content #DetailsForm {
  ion-card {
    .amt,
    .status {
      text-align: right;
    }
    h1 {
      font-weight: bold;
    }
  }

  ion-item {
    display: flex;

    &.trans {
      --min-height: 60px;
    }

    &.other {
      .payee {
        font-weight: bold;
      }
    }

    .col-1 {
      min-width: 110px;

      .relative {
        padding-top: 4px;
        font-size: 0.8rem;
      }
    }

    .payee {
      flex: 1;
      font-weight: bold;
    }

    .amt {
      text-align: right;
    }
    .payee {
      padding-left: 20px;
    }
  }
}
