.list {
  .normal-row {
    display: flex;
    align-items: center;
    padding-right: 10px;
    border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
    cursor: pointer;
    border-left: solid #666 3px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    .icon-container {
      display: inline-flex;
      padding-left: 16px;
      height: 100%;
      align-items: center;
    }

    .main-cell {
      margin-left: 13px;
      flex: 1;
      align-self: center;
      line-height: 1.8rem;
      cursor: pointer;
    }

    .notes {
      color: #888;
      padding: 0;
      margin-top: -10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      flex: 1;
      height: 29px;
      overflow-y: hidden;
      font-size: 0.8rem !important;

      &.no-notes {
        display: none;
      }
    }

    .ion-activatable {
      position: relative;
      overflow: hidden;
    }
  }

  .group1,
  .group2 {
    display: flex;
    align-items: center;
    padding-right: 10px;
    border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.06) 1px;
    cursor: pointer;
    background: var(--ion-color-medium);
    z-index: 1000 !important;

    .right-text {
      line-height: 0.7rem;
      position: relative;
      top: 9px;
      float: right;
      right: 6px;
    }

    &.normal {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    .main-cell {
      margin-left: 13px;
      flex: 1;
      align-self: center;
      line-height: 1.8rem;
      cursor: pointer;
    }
  }

  .group2 {
    background: var(--ion-color-medium);
  }
}
