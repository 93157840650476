#CategoriesListPage_Content #CategoryRow {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-left: solid 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Lato-Light';
  font-size: 0.9rem;
  align-self: center;
  color: var(--ion-text-color);
  background-color: rgba(var(--ion-color-medium-rgb), 0.25);
}

html.plt-ios {
  #CategoriesListPage_Content #CategoryRow {
    text-transform: capitalize;
  }
}
