#AccountsListPage_Content {
  .list {
    .normal-row {
      .main-cell {
        &.unread {
          font-weight: bold;
        }
        ion-badge {
          font-size: 0.8rem;
          vertical-align: middle;
          margin-top: -4px;
          margin-left: 12px;
        }
        ion-icon {
          position: relative;
          top: 2px;
          margin-left: 8px;
        }
        .notes {
          font-weight: normal;
        }
      }
    }

    .positive {
      font-weight: bold;
      color: rgba(var(--ion-color-success-rgb), 1);
    }
    .negative {
      font-weight: bold;
      color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
    }
  }

  .right-text {
    font-weight: normal !important;
  }
}
