#HaveAccountFooter {
  margin-top: 30px;

  .header {
    margin-left: 18px;
  }

  .sign-in-here {
    text-decoration: underline;
    color: var(--ion-color-primary);
  }
}
