#BankTransactionsListPage_Content {
  .list {
    ion-item-sliding {
      display: flex;
      align-items: center;
      padding: 0;
      // // padding-right: 0;
      // border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
      // cursor: pointer;
      // border-left: solid #666 3px;
      // border-top-left-radius: 2px;
      // border-bottom-left-radius: 2px;

      ion-item {
        height: 60px;
        width: 100%;
        padding-right: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        --background: --ion-color-light;
        background-color: --ion-color-light;
        &.even {
          background: rgba(var(--ion-color-medium-rgb), 0.2);
        }
        &.normal-row {
          border-left: none;
          display: flex;
          flex-direction: row;

          .payee {
            flex: 1;
          }

          .tran-notes {
            color: #888;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            overflow-y: hidden;
            font-size: 0.8rem !important;

            &.tran-no-notes {
              display: none;
            }
          }

          .date {
            flex: 0.08;
            div {
              min-width: 50px;
              text-align: center;
            }
          }

          .numbers {
            flex: 0.1;
            text-align: right;
            min-width: 75px;
            .positive {
              color: rgba(var(--ion-color-success-rgb), 1);
            }
            .negative {
              color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
            }
            .running-total {
              font-size: 0.8rem !important;
              &.positive {
                color: rgba(var(--ion-color-success-rgb), 1);
              }
              &.negative {
                color: rgba(var(--ion-color-danger-rgb), 0.5);
              }
            }
          }
        }
      }
    }
  }

  .group1 {
    .count {
      vertical-align: middle;
      margin-top: -4px;
      margin-left: 10px;
    }
  }
}
