#TransactionEditPage_Content #DetailsForm {
  ion-item {
    ion-icon {
      z-index: 1000;
      margin-top: -36px;
      margin-left: auto;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .circle-picker {
    margin-top: 22px;
    align-self: center;
    margin-bottom: 1px !important;
  }

  .split-btn {
    margin-top: 14px;
  }

  ion-segment-button {
    ion-icon,
    ion-label {
      color: rgba(var(--ion-color-light-contrast-rgb), 0.7);
    }

    &.segment-button-checked {
      // background-color: rgba(var(--ion-color-light-contrast-rgb), 0.02);
      ion-label,
      ion-icon {
        color: var(--ion-color-tertiary);
      }
    }
  }
}
