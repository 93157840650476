#LoginPage {
  background-color: var(--ion-color-medium);

  #LogoContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: calc(15px + var(--ion-safe-area-top));

    img {
      width: 65%;
      height: 65%;
      text-align: center;
    }
  }

  ion-content {
    align-self: center;
    max-width: 450px;
    background: transparent !important;
    background-color: transparent !important;
    --background: none;
  }

  ion-card-content {
    padding: 40px;
    padding-bottom: 20px;
  }

  .or {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    display: block;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    background: none;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--ion-text-color) !important;
  }

  ion-input {
    color: var(--ion-text-color) !important;
  }

  ion-text {
    text-align: center;
    text-transform: uppercase;

    h1 {
      font-size: 1.9rem;
    }

    h6 {
      font-size: 0.8rem;
      cursor: pointer;
    }
  }

  ion-spinner {
    margin-left: 10px;
  }

  ion-button.login-button {
    --background: #629656;
  }

  .forgot-password {
    display: flex;
    justify-content: center;
  }

  .google-button {
    img {
      width: 20px;
      height: 20px;
    }
  }

  .apple-button {
    margin-top: 20px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .error {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }

  html.md {
    ion-label.forced-floating {
      transform: translate3d(0, 50%, 0) scale(0.75) !important;
    }
  }

  html.plt-ios {
    ion-label.forced-floating {
      transform: translate3d(0, 10%, 0) scale(0.82) !important;
    }
  }
}

html.plt-ios {
  #LoginPage {
    ion-text.forgot-password {
      text-transform: capitalize !important;
    }

    #LogoContainer {
      padding-bottom: 0;
    }

    .or {
      margin-top: 20px;
      margin-bottom: 0;
    }

    .google-button,
    .apple-button {
      font-weight: bold;
      border: solid #444 1px;
      border-radius: 2px;
    }

    ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;
    }

    // ion-label {
    //   transform: translate3d(0, 10%, 0) scale(0.82) !important;
    // }

    // ion-input {
    //   border: solid #777 1px;
    //   border-radius: 2px;
    //   padding-left: 10px;
    // }

    ion-card {
      margin-top: 0;
    }

    ion-card-content {
      padding-top: 10px;
    }
  }
}
