#UpcomingBillsWidget {
  ion-item-sliding {
    display: flex;
    align-items: center;
    padding: 0;
    // // padding-right: 0;
    // border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
    // cursor: pointer;
    // border-left: solid #666 3px;
    // border-top-left-radius: 2px;
    // border-bottom-left-radius: 2px;

    ion-item {
      height: 60px;
      width: 100%;
      padding-right: 0;
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      --background: --ion-color-light;
      background-color: --ion-color-light;
      &.even {
        // --background: rgba(var(--ion-color-light-contrast-rgb), 0.04);
        background: rgba(var(--ion-color-medium-rgb), 0.2);
      }
      &.normal-row {
        border-left: none;

        div.account {
          line-height: 1.2rem;
          color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
        }

        div.categories {
          text-align: right;
          margin-right: 5px;
          ion-badge {
            margin-right: 5px;
          }
        }

        div.auto-paid {
          text-align: right;
          margin-right: 5px;
        }
        
        .date {
          flex: 0.08;
          div {
            min-width: 60px;
            text-align: center;
            color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
          }
        }
      }
    }

    .numbers {
      text-align: right;
      .positive {
        color: rgba(var(--ion-color-success-rgb), 1);
      }
      .negative {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
      }
      .running-total {
        font-size: 0.8rem !important;
        &.positive {
          color: rgba(var(--ion-color-success-rgb), 1);
        }
        &.negative {
          color: rgba(var(--ion-color-danger-rgb), 0.5);
        }
      }
    }
  }
}
