html.plt-ios.plt-hybrid {
  #BankTransactionsListPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #BankTransactionsListPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

html.plt-ios {
  #BankTransactionsListPage_Content {
    ion-list {
      margin-top: 0;
      z-index: 100;
    }
  }
}

#BankTransactionsListPage_Content {
  .no-items {
    font-family: Lato-Light;
    font-size: 14px;
    text-align: center;
    margin-top: 80px;
    height: 120px;
    color: #888;
  }
}

.fab-button-in-list {
  --border-radius: 8px;
  width: 120px;
  position: relative;
  right: 130px;
  align-self: flex-end;

  &.expense {
    width: 100px;
  }
  &.income {
    width: 95px;
  }
  &.transfer {
    width: 100px;
  }
  &.bill-payment {
    width: 120px;
  }
  &.bill-payment {
    width: 130px;
  }
  &.cc-payment {
    width: 180px;
  }
}
