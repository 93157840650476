#BankTransactionsListPageMoreMenu {
  // ion-icon {
  //   font-size: 26px !important;
  // }
  // .hidden {
  //   display: none;
  // }
}

#BankTransactionsListPageMoreMenu_Content {
  ion-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  ion-item-divider {
    min-height: 0 !important;
    --background: #666 !important;
  }

  ion-item {
    cursor: pointer;
    &.bold {
      font-weight: bold;
    }
  }

  i {
    color: var(--ion-text-color) !important;
  }

  ion-icon {
    --color: var(--ion-text-color) !important;
    color: var(--ion-text-color) !important;
  }
}
