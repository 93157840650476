#ReminderEditPage_Content {
  .list {
    ion-item-sliding {
      display: flex;
      align-items: center;
      padding: 0;
      // // padding-right: 0;
      // border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
      // cursor: pointer;
      // border-left: solid #666 3px;
      // border-top-left-radius: 2px;
      // border-bottom-left-radius: 2px;

      ion-item {
        height: 60px;
        width: 100%;
        padding-right: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        --background: --ion-color-light;
        background-color: --ion-color-light;
        &.even {
          // --background: rgba(var(--ion-color-light-contrast-rgb), 0.04);
          background: rgba(var(--ion-color-medium-rgb), .2);
        }
        &.normal-row {
          border-left: none;
          .date {
            div {
              width: 50px;
              text-align: center;
              margin-right: -10px;
            }
          }
        }
      }

      .numbers {
        text-align: right;
        .positive {
          color: rgba(var(--ion-color-success-rgb), 1);
        }
        .negative {
          color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
        }
        .running-total {
          font-size: 0.8rem !important;
          &.positive {
            color: rgba(var(--ion-color-success-rgb), 1);
          }
          &.negative {
            color: rgba(var(--ion-color-danger-rgb), 0.5);
          }
        }
      }
    }
  }

  .group-row {
    .balance {
      line-height: 0.7rem;
      position: relative;
      top: 6px;
      float: right;
      right: 6px;

      &.positive {
        color: rgba(var(--ion-color-success-rgb), 1);
      }
      &.negative {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
      }
    }
  }
}
