#TransactionsListPage_Content {
  .list {
    ion-item-sliding {
      display: flex;
      align-items: center;
      padding: 0;

      ion-item {
        height: 40px;
        width: 100%;
        padding-right: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        --background: --ion-color-light;
        background-color: --ion-color-light;
        &.even {
          background: rgba(var(--ion-color-medium-rgb), 0.2);
        }
        &.normal-row {
          border-left: none;
          display: flex;
          flex-direction: row;

          .payee {
            flex: 1;
          }

          .tran-notes {
            color: #888;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            overflow-y: hidden;
            font-size: 0.8rem !important;

            &.tran-no-notes {
              display: none;
            }
          }

          div.auto-paid {
            text-align: right;
            margin-right: 5px;
          }

          div.categories {
            text-align: right;
            margin-right: 5px;
            ion-badge {
              margin-right: 5px;
            }
          }

          .date {
            flex: 0.08;
            div {
              min-width: 60px;
              text-align: center;
              color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
            }
          }

          .numbers {
            flex: 0.1;
            text-align: right;
            min-width: 75px;

            .positive {
              color: rgba(var(--ion-color-success-rgb), 1);
            }
            .negative {
              color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
            }
            .running-total {
              font-size: 0.8rem !important;
              &.positive {
                color: rgba(var(--ion-color-success-rgb), 1);
              }
              &.negative {
                color: rgba(var(--ion-color-danger-rgb), 0.5);
              }
            }
          }
        }
      }
    }
  }

  .group-row {
    .balance {
      line-height: 0.7rem;
      position: relative;
      top: 6px;
      float: right;
      right: 6px;

      &.positive {
        color: rgba(var(--ion-color-success-rgb), 1);
      }
      &.negative {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
      }
    }
  }

  .last-row {
    z-index: 1000 !important;
    text-align: center;
    align-content: center;
    vertical-align: middle;
    display: flex;
    cursor: pointer;
    
    ion-text {
      height: 100%;
      flex: 1;
      padding-top: 8px;
    }

    #spinner-container {
      flex: 1;
      align-items: center;
      align-content: center;
      flex-direction: column;

      ion-spinner {
        height: 100%;
      }
    }
  }
}
